<template>
  <v-card flat>
    <b> &nbsp;&nbsp; {{ test }} Test at {{ date }}</b>
    <v-row style="margin: 8px">
      <v-row align="center" justify="center">
        <v-col cols="12" sm="1" md="3"> </v-col>
        <v-col cols="12" sm="2" md="2" align-self="center">Operation </v-col>
        <v-col cols="12" sm="2" md="2" align-self="center">Opening turns</v-col>
        <v-col cols="12" sm="2" md="2" align-self="center">Closing turns</v-col>

        <v-col cols="12" sm="2" md="1" align-self="center">PI</v-col>
        <v-col cols="12" sm="2" md="1" align-self="center">PF</v-col>
      </v-row>
      <v-row>
        <v-col cols="12" sm="12" md="12">
          <v-row
            class="rowh2"
            dense
            align="center"
            justify="center"
            v-for="item in list"
            :key="item.component_id"
          >
            <v-col cols="12" sm="2" md="3">
              <div style="font-size: 12px; margin-bottom: 12px">
                <span>{{ item.label }}</span>
              </div></v-col
            >
            <v-col cols="12" sm="2" md="2">
              <v-text-field
                align-self="center"
                class="rowh"
                v-model="item.operat"
                outlined
                dense
                :readonly="true"
              >
              </v-text-field>
            </v-col>

            <v-col cols="12" sm="2" md="2" align-self="center">
              <v-text-field
                align-self="center"
                class="rowh"
                autocomplete="off"
                v-model="item.o_turns"
                type="number"
                outlined
                dense
                :readonly="true"
              >
              </v-text-field>
            </v-col>

            <v-col cols="12" sm="2" md="2">
              <v-text-field
                :readonly="true"
                class="rowh"
                return-object
                autocomplete="off"
                v-model="item.c_turns"
                :disabled="item.o_turns == 0"
                type="number"
                outlined
                dense
              >
              </v-text-field>
            </v-col>
            <v-col cols="12" sm="2" md="1">
              <v-text-field
                :readonly="true"
                class="rowh"
                autocomplete="off"
                return-object
                v-model="item.i_pressure"
                type="number"
                outlined
                dense
              >
              </v-text-field>
            </v-col>
            <v-col cols="12" sm="2" md="1">
              <v-text-field
                :readonly="true"
                class="rowh"
                return-object
                autocomplete="off"
                v-model="item.f_pressure"
                type="number"
                outlined
                dense
              >
              </v-text-field>
            </v-col> </v-row
          ><br />
        </v-col>
      </v-row>
    </v-row>
  </v-card>
</template>

<script>
export default {
  components: {},
  props: {
    list: Array,
    test: String,
  },
  data() {
    return {};
  },
  mounted() {},
  computed: {
    date() {
      if (this.list.length > 0) return this.list[0].testdate;
      else return null;
    },
  },
  watch: {},
  methods: {},
  created() {
    this.pressure_unit = this.$store.state.options[0].pressure;
  },
};
</script>
<style>
.rowh2 {
  height: 40px !important;
  margin-bottom: 8px !important;
}
</style>
